<template>
  <section class="latest-stories">
    <div class="latest-stories__header">
      <div class="latest-stories__header__title">
        {{ title }}
      </div>
      <CustomCta
        v-if="link"
        type="label"
        class="latest-stories__header__subtitle"
        :link="localePath(link['link-url'])"
        :target="link.target || '_blank'"
      >
        {{ link['link-label'] }}
      </CustomCta>
    </div>
    <SfCarousel
      v-if="stories.length > 0"
      :settings="latestStoriesCarouselSettings"
    >
      <template #prev>
        <div />
      </template>
      <SfCarouselItem
        v-for="(story, index) in stories"
        :key="`latest-stories-story-${index}`"
        class="latest-stories__story"
      >
        <div v-if="story.header" class="latest-stories__story__header">
          {{ story.header }}
        </div>
        <div v-if="story.title" class="latest-stories__story__title">
          {{ story.title }}
        </div>
        <CustomCta
          v-if="story.link"
          class="latest-stories__story__link"
          type="label"
          :link="localePath(story.link['link-url'])"
        >
          <span class="latest-stories__story__link__label">
            {{ story.link['link-label'] }}
          </span>
        </CustomCta>
        <nuxt-img
          v-if="story.image"
          class="latest-stories__story__image"
          :src="isDesktop ? story.image.desktop : story.image.mobile"
          :alt="story.image.alt"
        />
        <div
          class="latest-stories__story__description"
          v-if="story.description"
        >
          {{ story.description }}
        </div>
      </SfCarouselItem>
      <template #next>
        <div />
      </template>
    </SfCarousel>
  </section>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import { CustomCta } from '~/components/General';

export default defineComponent({
  name: 'LatestStories',
  components: {
    CustomCta,
    SfCarousel,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => null,
    },
    storiesData: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const { app } = useContext();
    const isDesktop = app.$device.isDesktop;

    const latestStoriesCarouselSettings = {
      type: 'slider',
      gap: 0,
      perView: 2,
      peek: {
        before: 1,
        after: 230,
      },
      breakpoints: {
        768: {
          perView: 1,
          peek: {
            before: 1,
            after: 65,
          },
        },
      },
    };

    const stories = computed(() =>
      props.storiesData?.length > 0
        ? props.storiesData.filter((p) => p?.active)
        : []
    );

    return {
      latestStoriesCarouselSettings,
      isDesktop,
      stories,
    };
  },
});
</script>

<style lang="scss">
.latest-stories {
  width: 100%;
  @include desktop-boxed;
  padding: 2.5rem 0 2.5rem;
  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.4063rem;
    padding: 0 1.25rem 1.375rem;
    &__title {
      @include mobile-h5;
    }
  }
  &__story {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    &__header {
      @include label-s;
      padding-bottom: 0.625rem;
    }
    &__title {
      @include mobile-h6;
      padding-bottom: 1.25rem;
    }
    &__link {
      padding-bottom: 1.25rem;
      &__label {
        @include mobile-h6;
      }
    }
    &__description {
      padding-top: var(--spacer-sm);
      @include paragraph-m;
    }
    &__image {
      object-fit: contain;
      width: 100%;
    }
  }
  .sf-carousel {
    --carousel-width: 100%;
    margin-left: 20px;
    &__slides {
      border-top: var(--general-border);
    }
    &-item {
      * {
        flex: unset;
      }
      height: auto;
      justify-content: flex-start;
      border-right: var(--general-border);
      border-bottom: var(--general-border);
      &:nth-child(1) {
        border-left: var(--general-border);
      }
    }
  }
}
@include from-tablet-min {
  .latest-stories {
    max-height: var(--real-window-height);
    padding: 5rem 0 5rem;
    &__header {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 1.875rem;
      &__title {
        @include desktop-h4;
      }
    }
    &__story {
      &__title {
        @include desktop-h6;
      }
      &__link {
        &__label {
          @include desktop-h6;
        }
      }
      &__image {
        max-height: 498px;
      }
    }
    .sf-carousel {
      height: 100%;
      &-item {
        border-bottom: 2px solid black;
      }
      &__wrapper {
        height: 100%;
      }
    }
  }
}
</style>
